var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{attrs:{"hideLayout":_vm.hideLayout}},[(!_vm.hideLayout)?_c('PageHeader',{attrs:{"title":_vm.title}}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"}),_c('div',{staticClass:"col-xl-8"},[(_vm.notification.message)?_c('b-alert',{staticClass:"mt-1",attrs:{"variant":_vm.notification.type,"show":"","dismissible":""},on:{"dismissed":function($event){return _vm.$store.dispatch('notification/clear')}}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('div',{staticClass:"card"},[_c('b-overlay',{attrs:{"show":_vm.createPurchaseProcessing || _vm.fetchingEstates,"rounded":"md"}},[_c('div',{staticClass:"card-body"},[_c('form-wizard',{ref:"formWizard",attrs:{"color":"red"},on:{"on-complete":_vm.createPurchase}},[_c('tab-content',{attrs:{"icon":"mdi mdi-home","before-change":_vm.validateStep1}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"plots"}},[_vm._v("No. of Plots")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.no_of_plots),expression:"purchase.no_of_plots"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step1Completed &&
                                _vm.$v.purchase.no_of_plots.$error) ||
                              _vm.errors.no_of_plots,
                          },attrs:{"id":"plots","type":"number","name":"plots","placeholder":"Enter number of plots (eg. 10)"},domProps:{"value":(_vm.purchase.no_of_plots)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "no_of_plots", $event.target.value)}}}),(
                            (_vm.step1Completed &&
                              _vm.$v.purchase.no_of_plots.$error) ||
                            _vm.errors.no_of_plots
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.no_of_plots)?_c('span',[_vm._v(_vm._s(_vm.errors.no_of_plots[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.no_of_plots.required)?_c('span',[_vm._v("How many plots of land?  ")]):_vm._e(),(!_vm.$v.purchase.no_of_plots.Number)?_c('span',[_vm._v("This field must be a number.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"gender"}},[_vm._v("Select Estate")]),_c('div',{staticClass:"col-md-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.estate_id),expression:"purchase.estate_id"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step1Completed &&
                                _vm.$v.purchase.estate_id.$error) ||
                              _vm.errors.estate_id,
                          },attrs:{"name":"Phone","placeholder":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.purchase, "estate_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Select an Estate")]),_vm._l((_vm.estates),function(estate,index){return _c('option',{key:index,domProps:{"value":estate.id}},[_vm._v(_vm._s(estate.name))])})],2),(
                            (_vm.step1Completed &&
                              _vm.$v.purchase.estate_id.$error) ||
                            _vm.errors.estate_id
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.estate_id)?_c('span',[_vm._v(_vm._s(_vm.errors.estate_id[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.estate_id.required)?_c('span',[_vm._v("Select an estate")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.showPlan)+" ")])])])])])]),_c('tab-content',{attrs:{"icon":"mdi mdi-account-circle","before-change":_vm.validateStep2}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"userName"}},[_vm._v("First Name")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.firstname),expression:"purchase.firstname"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step2Completed &&
                                _vm.$v.purchase.firstname.$error) ||
                              _vm.errors.firstname,
                          },attrs:{"id":"userName","type":"text","placeholder":"Enter First Name"},domProps:{"value":(_vm.purchase.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "firstname", $event.target.value)}}}),(
                            (_vm.step2Completed &&
                              _vm.$v.purchase.firstname.$error) ||
                            _vm.errors.firstname
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.firstname)?_c('span',[_vm._v(_vm._s(_vm.errors.firstname[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.firstname.required)?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"userName"}},[_vm._v("Middle Name")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.middlename),expression:"purchase.middlename"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step2Completed &&
                                _vm.$v.purchase.middlename.$error) ||
                              _vm.errors.middlename,
                          },attrs:{"id":"userName","type":"text","name":"userName","placeholder":"Enter Middle Name"},domProps:{"value":(_vm.purchase.middlename)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "middlename", $event.target.value)}}}),(
                            (_vm.step2Completed &&
                              _vm.$v.purchase.middlename.$error) ||
                            _vm.errors.middlename
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.middlename)?_c('span',[_vm._v(_vm._s(_vm.errors.middlename[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.middlename.required)?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"userName"}},[_vm._v("Last Name")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.lastname),expression:"purchase.lastname"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step2Completed &&
                                _vm.$v.purchase.lastname.$error) ||
                              _vm.errors.lastname,
                          },attrs:{"id":"userName","type":"text","name":"userName","placeholder":"Enter Last Name"},domProps:{"value":(_vm.purchase.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "lastname", $event.target.value)}}}),(
                            (_vm.step2Completed && _vm.$v.purchase.lastname.$error) ||
                            _vm.errors.lastname
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.lastname)?_c('span',[_vm._v(_vm._s(_vm.errors.lastname[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.lastname.required)?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"email"}},[_vm._v("Email")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.email),expression:"purchase.email"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step2Completed && _vm.$v.purchase.email.$error) ||
                              _vm.errors.email,
                          },attrs:{"id":"email","type":"email","name":"email","placeholder":"abc@gmail.com"},domProps:{"value":(_vm.purchase.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "email", $event.target.value)}}}),(
                            (_vm.step2Completed && _vm.$v.purchase.email.$error) ||
                            _vm.errors.email
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.email)?_c('span',[_vm._v(_vm._s(_vm.errors.email[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.email.required)?_c('span',[_vm._v("This field is required")]):_vm._e(),(!_vm.$v.purchase.email.email)?_c('span',[_vm._v("Invalid email")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"phone"}},[_vm._v("Phone")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.phone),expression:"purchase.phone"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step2Completed && _vm.$v.purchase.phone.$error) ||
                              _vm.errors.phone,
                          },attrs:{"id":"phone","type":"tel","name":"phone","placeholder":"+2348012345679"},domProps:{"value":(_vm.purchase.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "phone", $event.target.value)}}}),(
                            (_vm.step2Completed && _vm.$v.purchase.phone.$error) ||
                            _vm.errors.phone
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.phone)?_c('span',[_vm._v(_vm._s(_vm.errors.phone[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.phone.required)?_c('span',[_vm._v("This field is required.  ")]):_vm._e(),(!_vm.$v.purchase.phone.Number)?_c('span',[_vm._v("This field must be a number")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"userName"}},[_vm._v("Agent Username")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.referrer_username),expression:"purchase.referrer_username"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step2Completed &&
                                _vm.$v.purchase.referrer_username.$error) ||
                              _vm.errors.referrer_username,
                          },attrs:{"id":"userName","type":"text","name":"userName","placeholder":"@agentUsername"},domProps:{"value":(_vm.purchase.referrer_username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "referrer_username", $event.target.value)}}}),(
                            (_vm.step2Completed &&
                              _vm.$v.purchase.referrer_username.$error) ||
                            _vm.errors.referrer_username
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.referrer_username)?_c('span',[_vm._v(_vm._s(_vm.errors.referrer_username[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.referrer_username.required)?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"occupation"}},[_vm._v("Occupation")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.occupation),expression:"purchase.occupation"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step2Completed &&
                                _vm.$v.purchase.occupation.$error) ||
                              _vm.errors.occupation,
                          },attrs:{"id":"occupation","type":"text","name":"occupation","placeholder":"Farmer or Pastor or Teacher e.t.c."},domProps:{"value":(_vm.purchase.occupation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "occupation", $event.target.value)}}}),(
                            (_vm.step2Completed &&
                              _vm.$v.purchase.occupation.$error) ||
                            _vm.errors.occupation
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.occupation)?_c('span',[_vm._v(_vm._s(_vm.errors.occupation[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.occupation.required)?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"gender"}},[_vm._v("Gender")]),_c('div',{staticClass:"col-md-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.gender),expression:"purchase.gender"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step2Completed && _vm.$v.purchase.gender.$error) ||
                              _vm.errors.gender,
                          },attrs:{"id":"gender","type":"radio","name":"Phone","placeholder":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.purchase, "gender", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select gender")]),_c('option',{attrs:{"value":"male"}},[_vm._v("Male")]),_c('option',{attrs:{"value":"female"}},[_vm._v("Female")])]),(
                            (_vm.step2Completed && _vm.$v.purchase.gender.$error) ||
                            _vm.errors.gender
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.gender)?_c('span',[_vm._v(_vm._s(_vm.errors.gender[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.gender.required)?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"address"}},[_vm._v("Address")]),_c('div',{staticClass:"col-md-9"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.address),expression:"purchase.address"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step2Completed &&
                                _vm.$v.purchase.address.$error) ||
                              _vm.errors.address,
                          },attrs:{"id":"address","type":"text","name":"Phone","placeholder":"No. 123 CadLands Street"},domProps:{"value":(_vm.purchase.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "address", $event.target.value)}}}),_vm._v(" "),(
                            (_vm.step2Completed && _vm.$v.purchase.address.$error) ||
                            _vm.errors.address
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.address)?_c('span',[_vm._v(_vm._s(_vm.errors.address[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.address.required)?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()])])])])]),_c('tab-content',{attrs:{"icon":"mdi mdi-face-profile","before-change":_vm.validateStep3}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"userName"}},[_vm._v("Next of Kin")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.next_of_kin),expression:"purchase.next_of_kin"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step3Completed &&
                                _vm.$v.purchase.next_of_kin.$error) ||
                              _vm.errors.next_of_kin,
                          },attrs:{"id":"userName","type":"text","name":"userName","placeholder":"Surname First"},domProps:{"value":(_vm.purchase.next_of_kin)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "next_of_kin", $event.target.value)}}}),(
                            (_vm.step3Completed &&
                              _vm.$v.purchase.next_of_kin.$error) ||
                            _vm.errors.next_of_kin
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.next_of_kin)?_c('span',[_vm._v(_vm._s(_vm.errors.next_of_kin[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.next_of_kin.required)?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"userName"}},[_vm._v("Next of Kin Relationship")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.next_of_kin_relationship),expression:"purchase.next_of_kin_relationship"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step3Completed &&
                                _vm.$v.purchase.next_of_kin_relationship
                                  .$error) ||
                              _vm.errors.next_of_kin_relationship,
                          },attrs:{"id":"userName","type":"text","name":"userName","placeholder":"Father or Mother or Brother e.t.c."},domProps:{"value":(_vm.purchase.next_of_kin_relationship)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "next_of_kin_relationship", $event.target.value)}}}),(
                            (_vm.step3Completed &&
                              _vm.$v.purchase.next_of_kin_relationship.$error) ||
                            _vm.errors.next_of_kin_relationship
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.next_of_kin_relationship)?_c('span',[_vm._v(_vm._s(_vm.errors.next_of_kin_relationship[0])+".  ")]):_vm._e(),(
                              !_vm.$v.purchase.next_of_kin_relationship.required
                            )?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"phone"}},[_vm._v("Next of Kin Phone")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.next_of_kin_phone),expression:"purchase.next_of_kin_phone"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step3Completed &&
                                _vm.$v.purchase.next_of_kin_phone.$error) ||
                              _vm.errors.next_of_kin_phone,
                          },attrs:{"id":"phone","type":"tel","name":"phone","placeholder":"+2348012341234"},domProps:{"value":(_vm.purchase.next_of_kin_phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "next_of_kin_phone", $event.target.value)}}}),(
                            (_vm.step3Completed &&
                              _vm.$v.purchase.next_of_kin_phone.$error) ||
                            _vm.errors.next_of_kin_phone
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.next_of_kin_phone)?_c('span',[_vm._v(_vm._s(_vm.errors.next_of_kin_phone[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.next_of_kin_phone.required)?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row mb-3"},[_c('label',{staticClass:"col-md-3 col-form-label",attrs:{"for":"address"}},[_vm._v("Next of Kin Address")]),_c('div',{staticClass:"col-md-9"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.purchase.next_of_kin_address),expression:"purchase.next_of_kin_address"}],staticClass:"form-control",class:{
                            'is-invalid':
                              (_vm.step3Completed &&
                                _vm.$v.purchase.next_of_kin_address.$error) ||
                              _vm.errors.next_of_kin_address,
                          },attrs:{"id":"address","type":"text","name":"Phone","placeholder":"No. 1234 CadLads Street"},domProps:{"value":(_vm.purchase.next_of_kin_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "next_of_kin_address", $event.target.value)}}}),_vm._v(" "),(
                            (_vm.step3Completed &&
                              _vm.$v.purchase.next_of_kin_address.$error) ||
                            _vm.errors.next_of_kin_address
                          )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.next_of_kin_address)?_c('span',[_vm._v(_vm._s(_vm.errors.next_of_kin_address[0])+".  ")]):_vm._e(),(!_vm.$v.purchase.next_of_kin_address.required)?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()])])])])]),_c('tab-content',{attrs:{"icon":"mdi mdi-checkbox-marked-circle-outline"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"mt-0"},[_c('i',{staticClass:"mdi mdi-check-all"})]),_c('h3',{staticClass:"mt-0"},[_vm._v("Thank you !")]),_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Click on the \"Finish\" button to complete your request.")])]),_c('p',{staticClass:"w-75 mb-2 mx-auto"},[_vm._v(" Proceed to make your payments to the account details below. ")]),_c('h5',[_vm._v("CadLands Real Estate Limited")]),_c('h5',[_vm._v("Fidelity: 5600669629")]),_c('h5',[_vm._v("UBA: 1022804540")]),_c('h5',[_vm._v("GTB: 0479275263")]),_c('h5',[_vm._v("Zenith: 1016034858")]),_c('p',{staticClass:"w-75 mb-2 mx-auto"},[_vm._v(" Send a screenshot of your proof of payment to your agent after payment. ")])])])])])],1)],1)])],1)],1),_c('div',{staticClass:"col-2"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }