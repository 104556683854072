<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { required, email } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { purchaseRequest } from "@/services/land.service";
import {
  fetchEstates,
} from "@/services/land.service";
/**
 * Buy Land component
 */
export default {
  page: {
    title: "Buy Land",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FormWizard, TabContent },
  data() {
    return {
      title: "Buy Land",
      step1Completed: false,
      step2Completed: false,
      step3Completed: false,
      createPurchaseProcessing: false,
      errors: {},
      estates: [],
      fetchingEstates: false,
      purchase: {
        // step 1
        no_of_plots: "",
        estate_id: "",
        // step 2
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        phone: "",
        referrer_username: this.$route.params.referrer,
        occupation: "",
        gender: "",
        address: "",
        // step 3
        next_of_kin: "",
        next_of_kin_relationship: "",
        next_of_kin_phone: "",
        next_of_kin_address: "",
      },
    };
  },
  validations: {
    purchase: {
      no_of_plots: { required, Number },
      estate_id: { required },
      firstname: { required },
      middlename: { required },
      lastname: { required },
      email: { required, email },
      phone: { required, Number },
      referrer_username: { required },
      occupation: { required },
      gender: { required },
      address: { required },
      next_of_kin: { required },
      next_of_kin_relationship: { required },
      next_of_kin_phone: { required },
      next_of_kin_address: { required },
    },
  },
  created() {
    if (this.$route.params.referrer == null) {
      this.$router.push("/");
    }

    // fetch estates
    this.fetchEstates();
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    showPlan() {
      if (!this.purchase.estate_id) return '';
      var selectedEstate = this.estates.find(estate => estate.id == this.purchase.estate_id);
      if (selectedEstate.plans) {
        // return 'selected';
        var planString = '';
        selectedEstate.plans.forEach(plan => {
          planString += plan.name + " @" + this.$options.filters.currencyFormat(plan.price) + '; ';
        })
        return planString
      } else {
        return ''
      }
    },
    hideLayout() {
      // hide the navigation layout if user is NOT logged in
      return this.$store.state.auth.user ? false : true;
    },
  },
  methods: {
    async fetchEstates() {
      try {
        this.errors = {};
        this.fetchingEstates = true;
        var response = await fetchEstates();
        this.estates = response.data.data;
        console.log(response.data);
        this.fetchingEstates = false;
      } catch (error) {
        this.fetchingEstates = false;
        console.log(error);
        switch (error.response.status) {
          case 500:
            this.$bvToast.toast(error.response.data.message, {
              title: "Error!",
              variant: "danger",
              solid: true,
            });
            break;
          case 403:
            this.$bvToast.toast(error.response.data.message, {
              title: "Error!",
              variant: "danger",
              solid: true,
            });
            break;
          default:
            this.$bvToast.toast("Something went wrong. Please try again!", {
              title: "Error!",
              variant: "danger",
              solid: true,
            });
            break;
        }
      }
    },
    async validateStep1() {
      this.$v.purchase.no_of_plots.$touch();
      this.$v.purchase.estate_id.$touch();
      this.step1Completed = true;
      if (
        this.$v.purchase.no_of_plots.$invalid ||
        this.$v.purchase.estate_id.$invalid
      ) {
        return;
      }
      return new Promise((resolve) => {
        resolve(true);
      });
    },
    async validateStep2() {
      this.$v.$touch();
      this.step2Completed = true;
      if (
        this.$v.purchase.firstname.$invalid ||
        this.$v.purchase.middlename.$invalid ||
        this.$v.purchase.lastname.$invalid ||
        this.$v.purchase.email.$invalid ||
        this.$v.purchase.phone.$invalid ||
        this.$v.purchase.referrer_username.$invalid ||
        this.$v.purchase.occupation.$invalid ||
        this.$v.purchase.gender.$invalid ||
        this.$v.purchase.address.$invalid
      ) {
        return;
      }
      return new Promise((resolve) => {
        resolve(true);
      });
    },
    async validateStep3() {
      this.$v.$touch();
      this.step3Completed = true;
      if (
        this.$v.purchase.next_of_kin.$invalid ||
        this.$v.purchase.next_of_kin_relationship.$invalid ||
        this.$v.purchase.next_of_kin_phone.$invalid ||
        this.$v.purchase.next_of_kin_address.$invalid
      ) {
        return;
      }
      return new Promise((resolve) => {
        resolve(true);
      });
    },
    async createPurchase() {
      if (this.purchaseCreated) {
        alert("Your Purchase Request is already created!");
        return false;
      }
      this.$store.dispatch("notification/clear");
      this.createPurchaseProcessing = true;

      try {
        var response = await purchaseRequest(this.purchase);
        this.createPurchaseProcessing = false;
        this.$store.dispatch("notification/success", response.data.message);
        this.purchaseCreated = true;
      } catch (error) {
        this.createPurchaseProcessing = false;
        if (error.response) {
          console.log(error.response);
          switch (error.response.status) {
            case 422:
              console.log(error.response.data.errors);
              this.$store.dispatch("notification/error", "An error occurred!");
              this.$refs.formWizard.changeTab(3, 0);
              this.errors = error.response.data.errors;
              break;
            case 500:
              this.$store.dispatch(
                "notification/error",
                error.response.data.message
              );
              break;
            default:
              this.$store.dispatch(
                "notification/error",
                "Something went wrong. Please try again!"
              );
              break;
          }
        }
      }
    },
  },
};
</script>

<template>
  <Layout :hideLayout="hideLayout">
    <PageHeader v-if="!hideLayout" :title="title"/>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-xl-8">
        <b-alert
          :variant="notification.type"
          class="mt-1"
          v-if="notification.message"
          show
          dismissible
          @dismissed="$store.dispatch('notification/clear')"
          >{{ notification.message }}</b-alert
        >
        <div class="card">
          <b-overlay :show="createPurchaseProcessing || fetchingEstates" rounded="md">
            <div class="card-body">
              <!-- <h4 class="card-title">Basic Wizard</h4> -->
              <form-wizard
                color="red"
                @on-complete="createPurchase"
                ref="formWizard"
              >
                <tab-content icon="mdi mdi-home" :before-change="validateStep1">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="plots"
                          >No. of Plots</label
                        >
                        <div class="col-md-9">
                          <input
                            id="plots"
                            type="number"
                            class="form-control"
                            name="plots"
                            v-model="purchase.no_of_plots"
                            placeholder="Enter number of plots (eg. 10)"
                            :class="{
                              'is-invalid':
                                (step1Completed &&
                                  $v.purchase.no_of_plots.$error) ||
                                errors.no_of_plots,
                            }"
                          />
                          <div
                            v-if="
                              (step1Completed &&
                                $v.purchase.no_of_plots.$error) ||
                              errors.no_of_plots
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.no_of_plots"
                              >{{ errors.no_of_plots[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.no_of_plots.required"
                              >How many plots of land? &nbsp;</span
                            >
                            <span v-if="!$v.purchase.no_of_plots.Number"
                              >This field must be a number.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="gender"
                          >Select Estate</label
                        >
                        <div class="col-md-9">
                          <select
                            class="form-control"
                            name="Phone"
                            placeholder=""
                            v-model="purchase.estate_id"
                            :class="{
                              'is-invalid':
                                (step1Completed &&
                                  $v.purchase.estate_id.$error) ||
                                errors.estate_id,
                            }"
                          >
                            <option value="" selected>Select an Estate</option>
                            <option :value="estate.id" v-for="(estate, index) in estates" :key="index">{{ estate.name }}</option>
                          </select>
                          <div
                            v-if="
                              (step1Completed &&
                                $v.purchase.estate_id.$error) ||
                              errors.estate_id
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.estate_id"
                              >{{ errors.estate_id[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.estate_id.required"
                              >Select an estate</span
                            >
                          </div>
                          <div class="mt-2">
                            {{ showPlan }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->
                </tab-content>
                <tab-content
                  icon="mdi mdi-account-circle"
                  :before-change="validateStep2"
                >
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="userName"
                          >First Name</label
                        >
                        <div class="col-md-9">
                          <input
                            id="userName"
                            type="text"
                            class="form-control"
                            placeholder="Enter First Name"
                            v-model="purchase.firstname"
                            :class="{
                              'is-invalid':
                                (step2Completed &&
                                  $v.purchase.firstname.$error) ||
                                errors.firstname,
                            }"
                          />
                          <div
                            v-if="
                              (step2Completed &&
                                $v.purchase.firstname.$error) ||
                              errors.firstname
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.firstname"
                              >{{ errors.firstname[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.firstname.required"
                              >This field is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="userName"
                          >Middle Name</label
                        >
                        <div class="col-md-9">
                          <input
                            id="userName"
                            type="text"
                            class="form-control"
                            name="userName"
                            placeholder="Enter Middle Name"
                            v-model="purchase.middlename"
                            :class="{
                              'is-invalid':
                                (step2Completed &&
                                  $v.purchase.middlename.$error) ||
                                errors.middlename,
                            }"
                          />
                          <div
                            v-if="
                              (step2Completed &&
                                $v.purchase.middlename.$error) ||
                              errors.middlename
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.middlename"
                              >{{ errors.middlename[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.middlename.required"
                              >This field is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="userName"
                          >Last Name</label
                        >
                        <div class="col-md-9">
                          <input
                            id="userName"
                            type="text"
                            class="form-control"
                            name="userName"
                            placeholder="Enter Last Name"
                            v-model="purchase.lastname"
                            :class="{
                              'is-invalid':
                                (step2Completed &&
                                  $v.purchase.lastname.$error) ||
                                errors.lastname,
                            }"
                          />
                          <div
                            v-if="
                              (step2Completed && $v.purchase.lastname.$error) ||
                              errors.lastname
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.lastname"
                              >{{ errors.lastname[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.lastname.required"
                              >This field is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="email"
                          >Email</label
                        >
                        <div class="col-md-9">
                          <input
                            id="email"
                            type="email"
                            name="email"
                            class="form-control"
                            placeholder="abc@gmail.com"
                            v-model="purchase.email"
                            :class="{
                              'is-invalid':
                                (step2Completed && $v.purchase.email.$error) ||
                                errors.email,
                            }"
                          />
                          <div
                            v-if="
                              (step2Completed && $v.purchase.email.$error) ||
                              errors.email
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.email"
                              >{{ errors.email[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.email.required"
                              >This field is required</span
                            >
                            <span v-if="!$v.purchase.email.email"
                              >Invalid email</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="phone"
                          >Phone</label
                        >
                        <div class="col-md-9">
                          <input
                            id="phone"
                            type="tel"
                            class="form-control"
                            name="phone"
                            placeholder="+2348012345679"
                            v-model="purchase.phone"
                            :class="{
                              'is-invalid':
                                (step2Completed && $v.purchase.phone.$error) ||
                                errors.phone,
                            }"
                          />
                          <div
                            v-if="
                              (step2Completed && $v.purchase.phone.$error) ||
                              errors.phone
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.phone"
                              >{{ errors.phone[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.phone.required"
                              >This field is required. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.phone.Number"
                              >This field must be a number</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="userName"
                          >Agent Username</label
                        >
                        <div class="col-md-9">
                          <input
                            id="userName"
                            type="text"
                            class="form-control"
                            name="userName"
                            placeholder="@agentUsername"
                            v-model="purchase.referrer_username"
                            :class="{
                              'is-invalid':
                                (step2Completed &&
                                  $v.purchase.referrer_username.$error) ||
                                errors.referrer_username,
                            }"
                          />
                          <div
                            v-if="
                              (step2Completed &&
                                $v.purchase.referrer_username.$error) ||
                              errors.referrer_username
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.referrer_username"
                              >{{ errors.referrer_username[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.referrer_username.required"
                              >This field is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="occupation"
                          >Occupation</label
                        >
                        <div class="col-md-9">
                          <input
                            id="occupation"
                            type="text"
                            class="form-control"
                            name="occupation"
                            placeholder="Farmer or Pastor or Teacher e.t.c."
                            v-model="purchase.occupation"
                            :class="{
                              'is-invalid':
                                (step2Completed &&
                                  $v.purchase.occupation.$error) ||
                                errors.occupation,
                            }"
                          />
                          <div
                            v-if="
                              (step2Completed &&
                                $v.purchase.occupation.$error) ||
                              errors.occupation
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.occupation"
                              >{{ errors.occupation[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.occupation.required"
                              >This field is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="gender"
                          >Gender</label
                        >
                        <div class="col-md-9">
                          <select
                            id="gender"
                            type="radio"
                            class="form-control"
                            name="Phone"
                            placeholder=""
                            v-model="purchase.gender"
                            :class="{
                              'is-invalid':
                                (step2Completed && $v.purchase.gender.$error) ||
                                errors.gender,
                            }"
                          >
                            <option value="">Select gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                          <div
                            v-if="
                              (step2Completed && $v.purchase.gender.$error) ||
                              errors.gender
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.gender"
                              >{{ errors.gender[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.gender.required"
                              >This field is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="address"
                          >Address</label
                        >
                        <div class="col-md-9">
                          <textarea
                            id="address"
                            type="text"
                            class="form-control"
                            name="Phone"
                            placeholder="No. 123 CadLands Street"
                            v-model="purchase.address"
                            :class="{
                              'is-invalid':
                                (step2Completed &&
                                  $v.purchase.address.$error) ||
                                errors.address,
                            }"
                          />
                          <div
                            v-if="
                              (step2Completed && $v.purchase.address.$error) ||
                              errors.address
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.address"
                              >{{ errors.address[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.address.required"
                              >This field is required</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->
                </tab-content>
                <tab-content
                  icon="mdi mdi-face-profile"
                  :before-change="validateStep3"
                >
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="userName"
                          >Next of Kin</label
                        >
                        <div class="col-md-9">
                          <input
                            id="userName"
                            type="text"
                            class="form-control"
                            name="userName"
                            placeholder="Surname First"
                            v-model="purchase.next_of_kin"
                            :class="{
                              'is-invalid':
                                (step3Completed &&
                                  $v.purchase.next_of_kin.$error) ||
                                errors.next_of_kin,
                            }"
                          />
                          <div
                            v-if="
                              (step3Completed &&
                                $v.purchase.next_of_kin.$error) ||
                              errors.next_of_kin
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.next_of_kin"
                              >{{ errors.next_of_kin[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.next_of_kin.required"
                              >This field is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="userName"
                          >Next of Kin Relationship</label
                        >
                        <div class="col-md-9">
                          <input
                            id="userName"
                            type="text"
                            class="form-control"
                            name="userName"
                            placeholder="Father or Mother or Brother e.t.c."
                            v-model="purchase.next_of_kin_relationship"
                            :class="{
                              'is-invalid':
                                (step3Completed &&
                                  $v.purchase.next_of_kin_relationship
                                    .$error) ||
                                errors.next_of_kin_relationship,
                            }"
                          />
                          <div
                            v-if="
                              (step3Completed &&
                                $v.purchase.next_of_kin_relationship.$error) ||
                              errors.next_of_kin_relationship
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.next_of_kin_relationship"
                              >{{ errors.next_of_kin_relationship[0] }}.
                              &nbsp;</span
                            >
                            <span
                              v-if="
                                !$v.purchase.next_of_kin_relationship.required
                              "
                              >This field is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="phone"
                          >Next of Kin Phone</label
                        >
                        <div class="col-md-9">
                          <input
                            id="phone"
                            type="tel"
                            class="form-control"
                            name="phone"
                            placeholder="+2348012341234"
                            v-model="purchase.next_of_kin_phone"
                            :class="{
                              'is-invalid':
                                (step3Completed &&
                                  $v.purchase.next_of_kin_phone.$error) ||
                                errors.next_of_kin_phone,
                            }"
                          />
                          <div
                            v-if="
                              (step3Completed &&
                                $v.purchase.next_of_kin_phone.$error) ||
                              errors.next_of_kin_phone
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.next_of_kin_phone"
                              >{{ errors.next_of_kin_phone[0] }}. &nbsp;</span
                            >
                            <span v-if="!$v.purchase.next_of_kin_phone.required"
                              >This field is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label class="col-md-3 col-form-label" for="address"
                          >Next of Kin Address</label
                        >
                        <div class="col-md-9">
                          <textarea
                            id="address"
                            type="text"
                            class="form-control"
                            name="Phone"
                            placeholder="No. 1234 CadLads Street"
                            v-model="purchase.next_of_kin_address"
                            :class="{
                              'is-invalid':
                                (step3Completed &&
                                  $v.purchase.next_of_kin_address.$error) ||
                                errors.next_of_kin_address,
                            }"
                          />
                          <div
                            v-if="
                              (step3Completed &&
                                $v.purchase.next_of_kin_address.$error) ||
                              errors.next_of_kin_address
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="errors.next_of_kin_address"
                              >{{ errors.next_of_kin_address[0] }}. &nbsp;</span
                            >
                            <span
                              v-if="!$v.purchase.next_of_kin_address.required"
                              >This field is required</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->
                </tab-content>
                <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                  <div class="row">
                    <div class="col-12">
                      <div class="text-center">
                        <h2 class="mt-0">
                          <i class="mdi mdi-check-all"></i>
                        </h2>
                        <h3 class="mt-0">Thank you !</h3>

                        <p>
                          <span class="font-weight-bold"
                            >Click on the "Finish" button to complete your
                            request.</span
                          >
                        </p>
                        <p class="w-75 mb-2 mx-auto">
                          Proceed to make your payments to the account details
                          below.
                        </p>
                        <h5>CadLands Real Estate Limited</h5>
                        <h5>Fidelity: 5600669629</h5>
                        <h5>UBA: 1022804540</h5>
                        <h5>GTB: 0479275263</h5>
                        <h5>Zenith: 1016034858</h5>
                        <p class="w-75 mb-2 mx-auto">
                          Send a screenshot of your proof of payment to your
                          agent after payment.
                        </p>
                      </div>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->
                </tab-content>
              </form-wizard>
            </div>
          </b-overlay>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <div class="col-2"></div>
    </div>
    <!-- end row -->
  </Layout>
</template>



